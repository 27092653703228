import $ from 'cash-dom'
import { getSubtotal } from './cart-toolkit'

const cartBelowThreshold = $('#cart-below-threshold')
const cartAboveThreshold = $('#cart-above-threshold')
const minSubtotal = Pura.cart.minimum_subtotal

const showBelowThreshold = () => {
  cartBelowThreshold.removeClass('hidden')
  cartAboveThreshold.addClass('hidden')
}

const showAboveThreshold = () => {
  cartBelowThreshold.addClass('hidden')
  cartAboveThreshold.removeClass('hidden')
}

const updateCartMessage = (data) => {
  const subtotal = getSubtotal(data)

  if (subtotal == 0) return

  if (subtotal >= minSubtotal) {
    showAboveThreshold()
    return
  }
  showBelowThreshold()
}

export { updateCartMessage }
