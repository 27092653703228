import $ from 'cash-dom'
import serialize from 'form-serialize'
import {
  addVariantToCart,
  addItemsToCart,
  getCart,
} from '../resources/shopify-cart'
import { cart } from './cart'
import {
  cartHasVariant,
  getItemInCart,
  generateUID,
  getURLParam,
} from '../utilities/toolkit'
import { getNewPackingId } from '../utilities/cart-packing-id'
import { getDiscountPercentage } from './product-pricing'

// Used by most product forms on regular proudct pages
$('[data-component="product-form"]').on('submit', async function (e) {
  e.preventDefault()
  const addToCartButton = $(this).find('[data-element="add-to-cart-button"]')
  const originalButtonState = addToCartButton.attr('data-state')

  const formData = serialize(this, { hash: true })

  const groupID = generateUID() || Date.now()
  const requestProducts = []
  let includedProducts = []
  let includedProductsAreSub
  let includedProductsSubFrequency
  let newItemObj
  let packingId = false
  let orderIndex = 0

  // Check for included products data
  if (
    Pura.productForm &&
    Pura.productForm.includedProducts &&
    Pura.productForm.includedProducts.length > 0
  ) {
    includedProducts = Pura.productForm.includedProducts || []
    // check forms first for sets, then Pura object, default to false
    includedProductsAreSub =
      formData['included-products-purchase-type'] == 'SUB' ||
      Pura.productForm.includedProductsAreSub ||
      false
    includedProductsSubFrequency =
      parseInt(formData['included-products-interval-frequency']) - 1

    const cartData = await getCart()

    // get new packing Id with most recent cart
    packingId = getNewPackingId(cartData)

    // add packing Id to main product
    formData.properties = formData.properties || {}
    formData.properties._pura_display_order = orderIndex++
    formData.properties._pura_group_id = groupID
    formData.properties._pura_UID = generateUID()
    formData.properties._pura_packing_id = packingId
    formData.properties._pura_full_price = (Pura.product.price / 100).toFixed(2)
    formData.properties._pura_full_price_currency = 'USD'
  }
  // check for max quantity
  if (
    formData.properties != undefined &&
    formData.properties._pura_cart_max_quantity != undefined
  ) {
    const itemInCart = getItemInCart(
      cart.getMostRecentCart(),
      'variant_id',
      formData.id
    )
    // item is in cart and above max quantity
    if (
      itemInCart &&
      itemInCart.quantity >= formData.properties._pura_cart_max_quantity
    ) {
      // disable button and return to prevent add to cart
      addToCartButton.prop('disabled', true)
      return
    }

    // check for quantity after we add to cart
    const itemInCartQuantity = itemInCart.quantity || 0
    const totalQuantity = itemInCartQuantity + parseInt(formData.quantity, 10)

    // item will get added to cart but has max quantity
    if (totalQuantity >= formData.properties._pura_cart_max_quantity) {
      addToCartButton.prop('disabled', true)
    }
  }

  // Update button state to adding to cart
  addToCartButton.attr('data-state', 'adding')

  // Make sure quantity is a number, not a string
  formData.quantity = parseInt(formData.quantity, 10)

  // If there are included products
  // Build included products array
  for (let i = 0; i < includedProducts.length; ++i) {
    const isFragranceOnlyBundle = Pura.product.metafields.fragrance_only_bundle
    const fragranceInheritsParent = isFragranceOnlyBundle && i === 0
    const parentHasDiscount =
      getDiscountPercentage(Pura.product.tags.join(', ')) || false

    newItemObj = {
      id: includedProducts[i].id,
      quantity: parseInt(formData.quantity, 10),
      properties: {
        _pura_group_id: groupID,
        _pura_group_role: 'child',
        _pura_UID: generateUID(),
        _pura_display_order: fragranceInheritsParent ? 0 : orderIndex++,
        _pura_packing_id: packingId,
        _pura_full_price: includedProducts[i].price.toFixed(2),
        _pura_full_price_currency: 'USD',
      },
    }

    if (isFragranceOnlyBundle && parentHasDiscount) {
      newItemObj.properties._pura_fragrance_bundle_discount = parentHasDiscount
    }

    if (fragranceInheritsParent) {
      newItemObj.properties._pura_cart_heading = `${Pura.product.title} includes:`
      newItemObj.properties._pura_cart_badge =
        includedProducts[i].type === 'Car Fragrance' ? 'Car' : 'Home'
    }

    // add packing ID if there is one
    if (packingId) {
      newItemObj.properties._pura_packing_id = packingId
    }

    // add selling plan if the item is a sub
    if (includedProductsAreSub) {
      newItemObj.selling_plan =
        includedProducts[i].sellingPlan[includedProductsSubFrequency].id
    }

    // push to request items array
    requestProducts.push(newItemObj)
  }

  // If there is a GWP product tied to the main product
  if (
    Pura.productForm &&
    Pura.productForm.gwp &&
    Pura.productForm.gwp.hasGWPProduct === 'true' &&
    Pura.productForm.gwp.variantID
  ) {
    const gwpHiddenInCart = Pura.productForm.gwp.hideInCart || false
    requestProducts.push({
      id: Pura.productForm.gwp.variantID,
      quantity: 1,
      properties: {
        _pura_hidden_in_cart: gwpHiddenInCart,
        _pura_group_id: groupID,
        _pura_group_role: 'child',
        _pura_gwp_child_product: true,
        _pura_UID: generateUID(),
        _pura_display_order: orderIndex++,
        _pura_full_price: (Pura.productForm.gwp.price / 100).toFixed(2),
        _pura_full_price_currency: 'USD',
      },
    })
  }
  // Set properties on main item if group items added
  if (requestProducts.length > 0) {
    formData.properties = formData.properties || {}
    formData.properties._pura_group_id = groupID
    formData.properties._pura_group_role = 'parent'
  }

  if (!Pura.product.metafields.fragrance_only_bundle) {
    // Consolidate products into one array
    requestProducts.push(formData)
  }

  // Add items to cart
  addItemsToCart({ items: requestProducts }).then(() => {
    cart.updateAndShow()
    addToCartButton.attr('data-state', originalButtonState)

    if ($('[data-component="product-form-modal"]').length > 0) {
      $('[data-component="product-form-modal"]').attr('aria-hidden', 'true')
    }
  })
})

// Used across the site as a quick add-to-cart button
const addToCartButton = () => {
  $('[data-action="add-variant-to-cart"]').on('click', function (e) {
    e.preventDefault()
    const variantId = $(this).attr('data-variant')
    const isFinalSale = $(this).attr('data-is-final-sale')
    const hasSubscription = $(this).attr('data-has-subscription')
    const sellingPlan = $(this).attr('data-selling-plan')
    const fullPrice = $(this).attr('data-full-price')
    const sampleImage = $(this).attr('data-sample-image')

    let selling_plan =
      hasSubscription === 'true' && sellingPlan ? +sellingPlan : ''
    let properties = {}

    // Check if sample is already in cart and skip else add sample to cart
    if ($(this).closest('[data-component="sample-item"]').length > 0) {
      properties = {
        _pura_samples: true,
        _pura_samples_image: sampleImage,
        _pura_hide_quantity: true,
      }

      getCart().then((cartData) => {
        if (!cartHasVariant(cartData, variantId)) {
          addVariantToCart(variantId, selling_plan, 1, properties).then(
            (cartData2) => {
              setTimeout(() => {
                cart.updateAndShow(cartData2)
              }, 500)
            }
          )
        }
      })

      return
    }

    if (fullPrice) {
      properties._pura_full_price = (+fullPrice / 100).toFixed(2)
      properties._pura_full_price_currency = 'USD'
    } else if (Pura.product) {
      properties._pura_full_price = (Pura.product.price / 100).toFixed(2)
      properties._pura_full_price_currency = 'USD'
    }

    if (isFinalSale === 'true') {
      properties._pura_cart_is_final_sale = 'true'
    }

    if (hasSubscription === 'false') {
      properties._pura_has_no_subscription = 'true'
    }

    // one time product add to cart
    addVariantToCart(variantId, selling_plan, 1, properties).then(() => {
      cart.updateAndShow()
    })
  })
}

const checkCartForMaxQuantity = () => {
  let productForm = $('[data-component="product-form"]')
  let addToCartButton = productForm.find('[data-element="add-to-cart-button"]')
  let maxQuantity = productForm.find(
    '[name="properties[_pura_cart_max_quantity]"]'
  )

  // exit if no max-quantity values
  if (maxQuantity.length == 0) return

  maxQuantity = maxQuantity.eq(0).val()
  getCart()
    .then((cartData) => {
      // check cart items for prodcut
      let itemInCart = getItemInCart(cartData, 'product_id', Pura.product.id)
      // exit if item isn't in cart
      if (itemInCart == false) return
      // if item quantity is at or above max quantity
      if (itemInCart.quantity >= maxQuantity) {
        // disable add to cart button
        addToCartButton.prop('disabled', true)
      }
    })
    .catch((err) => {
      console.log('error getting cart')
    })
}

// If URL contains deep link to variant then swich to one time to fix Google Merchant Errors
const variantParam = getURLParam('variant')
const subscriptionOnlyParam = getURLParam('subscription_only')

$(document).ready(function () {
  addToCartButton()

  if (Pura.product != undefined && variantParam != null) {
    $('.pdp-purchase-type-otp, #included-products-schedule-otp').each(
      function () {
        $(this).click()
      }
    )
  } else if (subscriptionOnlyParam) {
    $('.pdp-purchase-type-sub').each(function () {
      const subSelector = $(this).closest('li')
      const additionalPurchaseOptions = subSelector.siblings()

      if (subSelector && additionalPurchaseOptions.length) {
        additionalPurchaseOptions.hide()
        subSelector.addClass('rounded-b-lg')
        subSelector.removeClass('border-b-0')
      }
    })
  }
  checkCartForMaxQuantity()
})

export { addToCartButton }
