import $ from 'cash-dom'
import { arraysAreEqual } from '../utilities/toolkit'

const calculateVariant = (form) => {
  const options = form.find('[data-element="product-option"]')
  let values = []
  let variants

  if ($('[data-component="product-form-modal"]').length > 0) {
    const productId = $('[data-component="product-form-modal"]').attr(
      'data-product-id'
    )
    const productData = $(`#product-data-${productId}`).html()

    variants = JSON.parse(productData)
  } else {
    variants = window.Pura.product.variants
  }
  options.each((i) => {
    values.push(decodeURIComponent(options[i].value))
  })

  for (let i = 0; i < variants.length; ++i) {
    if (arraysAreEqual(variants[i].options, values)) {
      return variants[i]
    }
  }

  return false
}

const updateVariantInURL = (variantId) => {
  const params = `?variant=${variantId}`
  window.history.replaceState({}, null, `${window.location.pathname}${params}`)
}

const updateVariantIdInForm = (form, variant) => {
  const idField = form.find('[name="id"]')
  idField.attr('value', variant.id)
}

const updateAddToCartButton = (form, variant) => {
  const button = form.find('[data-element="add-to-cart-button"]')
  button.attr('data-state', '')

  if (variant == false) {
    button.prop('disabled', true)
  } else if (variant.available) {
    button.prop('disabled', false)
    button.attr('data-state', '')
  } else {
    button.prop('disabled', true)
    button.attr('data-state', 'out-of-stock')
  }
}

const updatePrice = (variant, productId) => {
  const priceElements = $(`[data-product-pricing-id="${productId}"]`)
  let price = (variant.price / 100).toFixed(2).replace('.00', '')
  price = window.Pura.currency.format.replace('{{amount}}', price)
  priceElements.html(price)
}

if (Pura.template.suffix !== 'get-started-experience') {
  $('[data-element="product-option"]').on('change', (e) => {
    e.preventDefault()

    const form = $(e.target.closest('[data-component="product-form"]'))
    const productId = form.attr('data-product-id')
    const variant = calculateVariant(form)

    if (variant != false) {
      updateVariantInURL(variant.id)
      updateVariantIdInForm(form, variant)
      updatePrice(variant, productId)
    }

    updateAddToCartButton(form, variant)
  })
}
const updateSwatchSelection = ($this) => {
  const $selectedOption = $this.val()

  $('[data-component="color-swatches"]').each(function () {
    const $swatchGroup = $(this)
    const $allOptionNames = $swatchGroup.find('[data-element="option-name"]')
    const $matchingOptionName = $swatchGroup
      .find(`input[value="${$selectedOption}"]`)
      .next('[data-element="option-name"]')

    $allOptionNames.addClass('hidden')
    $matchingOptionName.removeClass('hidden')

    $swatchGroup
      .find('input[type="radio"]')
      .filter(`[value="${$selectedOption}"]`)
      .prop('checked', true)
  })

  const defaultSelector = $('[data-element="product-option"]')
  if (defaultSelector.length) {
    defaultSelector.val($selectedOption).trigger('change')
  }
  const variantId = $this.data('value')

  if ($selectedOption?.toLowerCase() === 'black') {
    $('#build-your-set-cta').addClass('hidden')
    $('#build-your-set-cta-variant-2').removeClass('hidden')
  } else {
    $('#build-your-set-cta').removeClass('hidden')
    $('#build-your-set-cta-variant-2').addClass('hidden')
  }

  if (Pura.template.suffix == 'bys-landing-page') {
    $('[data-element="bys-add-to-cart"]').data('variant', variantId)
  }

  if (Pura.template.suffix == 'get-started-experience') {
    const mobileImages = $('[data-product-handle="pura-plus"]').find(
      '[data-element="mobile-image"]'
    )
    const variantImages = mobileImages.find('img')
    variantImages.addClass('hidden')

    const variantImage = variantImages.filter(function matchingVariantImage() {
      return $(this).attr('alt').toLowerCase() === $selectedOption.toLowerCase()
    })

    if (variantImage.length) {
      variantImage.removeClass('hidden')
    } else {
      variantImages.first().removeClass('hidden')
    }
  }

  const matchingSlider = $(
    `[data-component="product-slider-gallery-${variantId}"]`
  )
  $('[data-component^="product-slider-gallery"]').hide()

  if (matchingSlider.length) {
    const slider = $(matchingSlider).find(
      '[data-component="variant-gallery-sliders"]'
    )

    if (slider.hasClass('slick-initialized')) {
      slider.slick('unslick')
    }

    slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 0,
      arrows: true,
      asNavFor: `.thumb-slider-${variantId}`,
      prevArrow: $(`[data-action="previous"]`),
      nextArrow: $(`[data-action="next"]`),
    })

    matchingSlider.show().removeClass('hidden')
  }
}

const handleSwatchClick = ($colorSwatches) => {
  $($colorSwatches).on('change', function (e) {
    if ($(this).prop('disabled')) {
      e.preventDefault()
      return
    }
    updateSwatchSelection($(this))
  })

  $('[data-component="color-swatches"] label').on('keydown', (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      const $input = $(e.currentTarget).find('input[type="radio"]')
      $input.prop('checked', true).trigger('change')
    }
  })
}

$(document).ready(() => {
  const $colorSwatches = $(
    '[data-component="color-swatches"] input[type="radio"]'
  )
  if ($colorSwatches) {
    handleSwatchClick($colorSwatches)
  }
})

export { handleSwatchClick }
