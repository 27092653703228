import $ from 'cash-dom'
import './cart-item'
import './cart-checkout'
import './cart-gift-bag'
import { updateCartBump } from './cart-bump'
import { getCart } from '../resources/shopify-cart'
import { getCount } from './cart-toolkit'
import { updateCartMessage } from './cart-message'
import { updateCartCheckoutSavingsMessage } from './cart-checkout-savings-message'
import { buildCartSection } from './cart-section'
import { getCartUpsells } from './cart-upsell-items'
import { showScentSamples } from './cart-sample-items'
import { updateGWP } from './cart-gift-with-purchase'
import { generateCartItemsHTML } from './cart-render-items'
import { checkCartForBYGS } from './cart-BYGS'
import { updateAttributes } from './cart-attributes'
import { updateProgressBar } from './cart-progress-bar'
import { showToast } from './toast'
import { dataLayerViewCart } from '../tracking/view-cart'
import { updateCheckoutGift } from './checkout-gift'

class Cart {
  constructor() {
    const _ = this
    _.currencyFormat = window.Pura.currency.format
    _.cart = $('#cart')
    _.cartItems = $('[data-element="cart-items"]')
    _.cartFullItems = $('[data-element="cart-full-item"]')
    _.cartEmptyPanel = $('[data-element="cart-empty-panel"]')
    _.cartCount = $('[data-content="cart-count"]')
    _.cartShippingMessgae = $('[data-element="shipping-message"]')
    _.mostRecentCart = {}

    _.open = false
    _.bindUI()

    getCart()
      .then((cartData) => {
        _.mostRecentCart = cartData
        _.update(cartData, false)
      })
      .catch((error) => {
        console.error(error)
      })

    _.chechHash()
  }

  bindUI() {
    $('[data-action="show-cart"]').on('click', (e) => {
      e.preventDefault()
      e.target.setAttribute('aria-expanded', 'true')
      this.show()
    })

    $('[data-action="hide-cart"]').on('click', (e) => {
      e.preventDefault()
      this.hide()
    })
  }

  show() {
    const _ = this
    _.cart.attr('aria-hidden', 'false')
    _.open = true

    dataLayerViewCart()
    document.body.classList.add('overflow-hidden')

    const x = window.scrollX
    const y = window.scrollY

    // Give DOM a moment to refactor itself
    setTimeout(() => {
      _.cart.find('focus-trap-start').eq(0).focus()
      // prevent page from scrolling
      window.scrollTo(x, y)
    }, 200)
  }

  hide() {
    this.cart.attr('aria-hidden', 'true')
    document.body.classList.remove('overflow-hidden')
    $('[aria-controls="cart"][aria-expanded="true"]').focus()
    $('[aria-controls="cart"]').attr('aria-expanded', 'false')
    this.open = false
  }

  chechHash() {
    if (window.location.hash == '#show-cart') {
      // Let the rest of the page load for a second
      // so it doesn't feel so immediate and jerky

      setTimeout(() => {
        this.show()
      }, 1000)
    }
  }

  checkIfCartEmpty(cartData) {
    const _ = this
    if (getCount(cartData) > 0) {
      _.cartFullItems.removeClass('hidden')
      _.cartShippingMessgae.removeClass('hidden')
      _.cartEmptyPanel.addClass('hidden')
      $('#cart_has_items').removeClass('hidden').addClass('visible')
      $('#empty_cart').addClass('hidden').removeClass('visible')
      $('#empty_cart').addClass('hidden').removeClass('visible')
      return
    }

    _.cartFullItems.addClass('hidden')
    _.cartShippingMessgae.addClass('hidden')
    _.cartEmptyPanel.removeClass('hidden')

    $('#cart_has_items').addClass('hidden').removeClass('visible')
    $('#empty_cart').removeClass('hidden').addClass('visible')
  }

  renderCartItems(cartData) {
    this.cartItems.html(generateCartItemsHTML(cartData))
  }

  updateSubtotal(cartData) {
    let totalSavings = (cartData.items_subtotal_price / 100).toFixed(2)
    totalSavings = this.currencyFormat.replace('{{amount}}', totalSavings)
    $('[data-element="cart-subtotal"]').html(totalSavings)
  }

  update(cartData, show) {
    const _ = this
    updateGWP(cartData, (updatedCart) => {
      _.checkIfCartEmpty(updatedCart)
      _.renderCartItems(updatedCart)
      _.updateCartCount(updatedCart)
      _.updateSubtotal(updatedCart)
      updateProgressBar(updatedCart)
      updateCheckoutGift(updatedCart)
      updateCartMessage(updatedCart)
      updateAttributes(updatedCart)
      getCartUpsells(updatedCart)
      buildCartSection(updatedCart)
      showScentSamples(updatedCart)
      updateCartBump(updatedCart)
      updateCartCheckoutSavingsMessage(updatedCart)
      checkCartForBYGS(updatedCart)

      if (show) {
        if (
          Pura.collection?.disable_slide_out_cart?.includes(
            Pura.collection.handle
          )
        ) {
          showToast()
          return
        }

        setTimeout(() => {
          _.show()
        }, 50)
      }
    })
  }

  updateAndShow(cartData) {
    if (cartData != null) {
      this.mostRecentCart = cartData
      this.update(cartData, true)
      return
    }

    getCart()
      .then((cartData2) => {
        this.mostRecentCart = cartData2
        this.update(cartData2, true)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  updateCartCount(cartData) {
    const count = getCount(cartData)
    this.cartCount.html(count.toString())
  }

  getMostRecentCart() {
    return this.mostRecentCart
  }
}

const cart = new Cart()
Pura.cart.panel = cart

export { cart }
