import $ from 'cash-dom'
import { gwpCartItem } from '../templates/gwp-item'
import { addItemsToCart, updateCart } from '../resources/shopify-cart'
import { getCookie } from '../utilities/cookies'

// Panels
const gwpPanel = $('[data-element="cart-gwp-panel"]')
const gwpInCartsList = $('[data-element="cart-gwp-items"]')

// Thresholds and GWP configurations
const gwpEnabled = Pura.cart.gwp.enabled
const gwpThreshold1 = Pura.cart.gwp.threshold
const gwpThreshold2 = Pura.cart.gwp.threshold_2

// Products for two tiers
let gwpProducts =
  Pura.cart.gwp.option === 'gwp_product'
    ? Pura.cart.gwp_products.gwp_products
    : Pura.cart.gwp_collection.gwp_collection || []

const gwpVariantId1 = gwpProducts[0]?.variant_id || null
const gwpInStock1 = gwpProducts[0]?.available || false
const gwpVariantId2 = gwpProducts[1]?.variant_id || null
const gwpInStock2 = gwpProducts[1]?.available || false

const getGWPInCart = (cartData, variantId) =>
  cartData.items?.find(
    (item) =>
      item.properties?._pura_gwp_product && item.variant_id === variantId
  ) || null

const hasGiftCard = (cartData) =>
  cartData.items?.some((item) => item.product_id === 6985521496173) || false

const updateGWP = (data, callback) => {
  if (!data) return
  const gwpTier1Removed = getCookie('gwpTier1Removed')
  const gwpTier2Removed = getCookie('gwpTier2Removed')

  if (Pura.product?.id === 6985521496173 && hasGiftCard(data)) {
    callback(data)
    return
  }

  const cartSubtotal = data.total_price / 100
  const gwpInCart1 = getGWPInCart(data, gwpVariantId1)
  const gwpInCart2 = getGWPInCart(data, gwpVariantId2)

  let itemsToAdd = []
  let itemsToRemove = []

  if (data.items) {
    data.items.forEach((item) => {
      if (
        (!gwpEnabled && item.properties?._pura_gwp_product) ||
        (item.properties?._pura_gwp_product &&
          item.variant_id !== gwpVariantId1 &&
          item.variant_id !== gwpVariantId2)
      ) {
        itemsToRemove.push(item.key)
      }
    })
  }

  if (gwpVariantId1 && gwpInStock1 && !gwpTier1Removed) {
    if (cartSubtotal >= gwpThreshold1 && !gwpInCart1) {
      itemsToAdd.push({
        id: gwpVariantId1,
        quantity: 1,
        properties: getGWPProperties(gwpVariantId1),
      })
    } else if (cartSubtotal < gwpThreshold1 && gwpInCart1) {
      itemsToRemove.push(gwpInCart1.key)
    }
  }

  if (gwpVariantId2 && gwpInStock2 && !gwpTier2Removed) {
    if (cartSubtotal >= gwpThreshold2 && !gwpInCart2) {
      itemsToAdd.push({
        id: gwpVariantId2,
        quantity: 1,
        properties: getGWPProperties(gwpVariantId2),
      })
    } else if (cartSubtotal < gwpThreshold2 && gwpInCart2) {
      itemsToRemove.push(gwpInCart2.key)
    }
  }

  if (itemsToAdd.length > 0 && gwpEnabled) {
    addItemsToCart({ items: itemsToAdd }).then((res) => callback(res))
    updateDisplay(cartSubtotal, gwpTier1Removed, gwpTier2Removed)
  } else if (itemsToRemove.length > 0) {
    removeProductsFromCart(itemsToRemove, callback)
    if (gwpEnabled) {
      updateDisplay(cartSubtotal, gwpTier1Removed, gwpTier2Removed)
    }
  } else {
    updateDisplay(cartSubtotal, gwpTier1Removed, gwpTier2Removed)
    callback(data)
  }
}

const getGWPProperties = (variantId) => {
  const properties = {
    _pura_gwp_product: true,
    _pura_hide_quantity: true,
    _pura_hide_remove: !Pura.cart.gwp.enable_remove_button,
  }

  if (variantId === 41790095884397) {
    properties._pura_has_no_subscription = 'true'
  }
  return properties
}

const removeProductsFromCart = (itemKeys, callback) => {
  const updates = itemKeys.reduce((acc, key) => {
    acc[key] = 0
    return acc
  }, {})
  updateCart({ updates }).then((res) => callback(res))
}

const updateDisplay = (cartSubtotal, gwpTier1Removed, gwpTier2Removed) => {
  gwpPanel.addClass('hidden')

  if (!gwpEnabled) {
    return
  }

  let html = ''
  let addedGwp1 = false
  let addedGwp2 = false

  if (cartSubtotal < gwpThreshold1 && gwpInStock1 && !gwpTier1Removed) {
    html += gwpCartItem(gwpProducts[0])
    addedGwp1 = true
  }
  if (cartSubtotal < gwpThreshold2 && gwpInStock2 && !gwpTier2Removed) {
    html += gwpCartItem(gwpProducts[1])
    addedGwp2 = true
  }

  if (html) {
    gwpPanel.removeClass('hidden')
    gwpInCartsList.html(html)

    if (addedGwp1 && addedGwp2) {
      gwpPanel.find('img').removeClass('w-[100px]')
      gwpPanel.find('.text-caption').css('margin-left', '0px')
    } else {
      gwpPanel.find('img').addClass('w-[100px]')
      gwpPanel.find('.text-caption').css('margin-left', '28px')
    }
  }
}

export { updateGWP }
