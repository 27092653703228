import $ from 'cash-dom'
import { getCollection } from '../resources/shopify-collection'
import { addToCartButton } from '../components/product-form'
import { productPricing } from '../components/product-pricing'

let collectionHandle = false
let collectionProducts = document.getElementById('collection-products')
let collectionFilters = document.querySelector('[data-element="filter-panel"]')
let collectionPageCount = 1
let filterCount = ''

const paginationButton = document.getElementById('collection-pagination-button')
const spinner = document.getElementById('spinner')
let sortOption = false
let tags = ''

if (Pura.collection) {
  // set handle
  if (Pura.collection.handle) {
    collectionHandle = Pura.collection.handle
  }
  // show oos items on load >= 20 skus
  if (paginationButton && paginationButton.classList.contains('hidden')) {
    const collectionItems = document.getElementById('collection-products')
    const outOfStockItems = document.getElementById('out-of-stock')

    if (outOfStockItems) {
      $(collectionItems).append(outOfStockItems.innerHTML)
    }
  }
}

const isFreePricingApplicable = (url) => {
  const step = Pura.BYS?.current_step || 1
  return (
    (step === 1 &&
      Pura.BYS?.panel_1_fragrances_are_free &&
      Pura.BYS?.panel_1_collection_handle === url.split('/')[2]) ||
    (step === 2 &&
      Pura.BYS?.panel_2_fragrances_are_free &&
      Pura.BYS?.panel_2_collection_handle === url.split('/')[2])
  )
}

const buildCollectionURL = () => {
  if (!collectionHandle) return

  let url = `/collections/${collectionHandle}/${tags}?`

  if (sortOption != false) {
    url += 'sort_by=' + sortOption + '&page=' + collectionPageCount
  } else {
    url += 'page=' + collectionPageCount
  }

  return url
}

const buildFilterBadge = (count) => {
  if (count && count > 0) {
    $('[data-element="filter-count"]').html('(' + count + ')')
    $('[data-component="filter-badge"]').removeClass('hidden')
    return
  }

  $('[data-component="filter-badge"]').addClass('hidden')
}

const setProductPrice = (isFree = false) => {
  const container = $('#collection-products')
  const thumbnails = container.find('.product-thumbnail')
  const bundleTags = Pura.product?.tags?.join(', ') || ''
  const ignoreAllDiscounting = Pura.BYS?.ignore_all_discounting || false

  thumbnails.each((_, thumb) => {
    const $thumb = $(thumb)
    const scriptTag = $thumb.find('script[type="application/json"]')
    const priceElement = $thumb.find('[data-content="product-price"]')

    if (!scriptTag.length || !priceElement.length) {
      console.warn('Missing required elements in product thumbnail:', thumb)
      return
    }

    let productData
    try {
      productData = JSON.parse(scriptTag.text() || scriptTag.html())
    } catch (error) {
      console.error('Failed to parse product data:', error)
      return
    }

    const { subscriptionPrice, oneTimePurchasePrice, comparePrice } =
      productPricing(productData, bundleTags, ignoreAllDiscounting)

    const finalComparePrice = isFree
      ? Math.round(productData.price * 0.8 * 100) / 100
      : comparePrice

    const displayPrice =
      subscriptionPrice === 0 || isFree
        ? 'FREE'
        : `$${subscriptionPrice.toFixed(2)}`

    priceElement
      .attr('data-product-pricing-id', productData.id)
      .attr('data-sale', `$${subscriptionPrice.toFixed(2)}`)
      .attr('data-otp', `$${oneTimePurchasePrice.toFixed(2)}`)
      .attr('data-compare', `$${finalComparePrice.toFixed(2)}`)

    let innerHTMLContent = `
      <span data-tag="${displayPrice}" aria-label="subscription price is: ${displayPrice}">${displayPrice}</span>`

    if (finalComparePrice > oneTimePurchasePrice || isFree) {
      innerHTMLContent += `
        <span aria-label="original price is: $${finalComparePrice.toFixed(
          2
        )}" class="line-through opacity-50">
          $${finalComparePrice.toFixed(2)}
        </span>`
    }

    priceElement.html(innerHTMLContent)
  })
}

const reloadCollection = (reloadFilterPanel = false, callback) => {
  collectionPageCount = 1
  let url = buildCollectionURL()

  $('[data-action="search-bogo"]').val('')

  getCollection(url)
    .then((res) => {
      const parser = new DOMParser()
      const doc = parser.parseFromString(res, 'text/html')
      const oosProducts = doc.getElementById('out-of-stock')
      const pagination = doc.getElementById('collection-pagination-button')
      const products = doc.getElementById('collection-products')
      const filterPanel = doc.querySelector('[data-element="filter-panel"]')

      if (pagination && !pagination.classList.contains('hidden')) {
        $('#collection-pagination-button').removeClass('hidden')
      } else {
        $('#collection-pagination-button').addClass('hidden')
        if (oosProducts != null) {
          $(products).append(oosProducts.innerHTML)
        }
      }
      // Update DOM
      if (products == undefined || products.children.length == 0) {
        collectionProducts.innerHTML = `<p class="col-span-2">
        There are no results using these filters. <br>
        Please select different filters.
      </p>`
      } else {
        collectionProducts.innerHTML = products.innerHTML
      }

      // reload filters panel when changing collection urls
      if (reloadFilterPanel) {
        collectionFilters.innerHTML = filterPanel.innerHTML
      }

      // Only update URL if it is a collection page
      if (location.pathname.indexOf('collection') != -1) {
        window.history.pushState({}, '', url)
      }
      addToCartButton()

      if (Pura.template.suffix === 'get-started-experience') {
        $('[data-action="select-fragrance"]').each((_, button) => {
          $(button).removeClass('hidden')
        })
      }

      // transform pricing if on BYS on offering free products
      if (Pura.BYS) {
        const freeItem = isFreePricingApplicable(url)
        setProductPrice(freeItem)
      }

      if (callback && typeof callback === 'function') {
        callback()
      }
    })
    .catch((error) => {
      console.error(error)
    })
}

const loadNextPage = () => {
  collectionPageCount++
  let nextPageURL = buildCollectionURL()
  $(spinner).removeClass('hidden')

  getCollection(nextPageURL)
    .then((res) => {
      const parser = new DOMParser()
      const doc = parser.parseFromString(res, 'text/html')
      const newProductCount =
        doc.getElementsByClassName('product-thumbnail').length
      if (newProductCount == 0) {
        $(spinner).addClass('hidden')
        $('#collection-pagination-button').addClass('hidden')
        return
      }
      const newProducts = doc.getElementById('collection-products').innerHTML
      const outOfStock = $('#out-of-stock').html()
      const pagination = doc.getElementById('collection-pagination-button')

      // Update DOM
      $(collectionProducts).append(newProducts)
      addToCartButton()

      // transform pricing if on BYS on offering free products
      if (Pura.BYS) {
        const freeItem = isFreePricingApplicable(nextPageURL)
        setProductPrice(freeItem)
      }
      if (pagination && !pagination.classList.contains('hidden')) {
        $('#collection-pagination-button').removeClass('hidden')
      } else {
        $('#collection-pagination-button').addClass('hidden')
        if (outOfStock != null) {
          $(collectionProducts).append(outOfStock)
        }
      }

      if (Pura.template.suffix === 'get-started-experience') {
        $('[data-action="select-fragrance"]').each((_, button) => {
          $(button).removeClass('hidden')
        })
      }

      $(spinner).addClass('hidden')
    })
    .catch((error) => {
      console.error(error)
      $(spinner).addClass('hidden')
    })
}

const clearFilters = (reload = true) => {
  collectionPageCount = 1
  tags = ''

  $('[data-component="filter-badge"]').addClass('hidden')
  $('[data-element="filter-item"] input:checked').prop('checked', false)

  if (reload) {
    reloadCollection()
  }
}

const setCollectionHandle = (newHandle, callback) => {
  collectionHandle = newHandle

  clearFilters(false)
  reloadCollection(true, (dataFromReload) => {
    if (callback && typeof callback === 'function') {
      callback(dataFromReload)
    }
  })
}

const setFilterCount = (count) => {
  filterCount = count
  buildFilterBadge(filterCount)
}

const setSortOption = (newOption) => {
  sortOption = newOption.trim()
  if (sortOption == '') sortOption = false

  reloadCollection()
}

const setTags = (newtags) => {
  tags = newtags
  reloadCollection()
}

if (Pura.collection && Pura.collection.current_tags.length > 0) {
  tags = Pura.collection.current_tags.join('+')
  setFilterCount(Pura.collection.current_tags.length)
}

if (
  Pura.template.suffix === 'BYB-v3-v4' ||
  Pura.template.suffix === 'BYB-v3-v4-code'
) {
  sortOption = 'manual'
}

if (Pura.collection) {
  const loadMoreObserver = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        if (
          sortOption == false &&
          window.location.search.includes('best-selling')
        ) {
          sortOption = 'best-selling'
        }
        loadNextPage()
      }
    },
    {
      rootMargin: '500px',
    }
  )

  if (paginationButton) {
    loadMoreObserver.observe(paginationButton)
  }
}

export {
  clearFilters,
  reloadCollection,
  setCollectionHandle,
  setFilterCount,
  setSortOption,
  setTags,
  setProductPrice,
  isFreePricingApplicable,
  buildCollectionURL,
}
