import { getDiscountPricing } from './sitewide-discount'

const parsePrice = (price) => {
  if (typeof price !== 'string') {
    return (price / 100).toFixed(2)
  }

  return parseFloat(price.replace('$', '').replace('+', ''))
}

const getDiscountPercentage = (tags) => {
  const tagString = Array.isArray(tags) ? tags.join(' ') : String(tags)

  const match = tagString.match(/discount_(\d+)/)
  return match ? parseInt(match[1], 10) : 0
}

const productPricing = (
  item,
  overrideTags = null,
  ignoreAllDiscounting = null
) => {
  const basePrice = item.bundle_price
    ? parsePrice(item.bundle_price)
    : parsePrice(item.price)

  const comparePrice = item.comparePrice
    ? parsePrice(item.comparePrice)
    : parsePrice(item.price)

  const bundleDiscount = overrideTags && getDiscountPercentage(overrideTags)
  const lineItemDiscount = getDiscountPercentage(item.tags)
  const discountPercentage = ignoreAllDiscounting
    ? 0
    : bundleDiscount || lineItemDiscount

  const discountedPrice = getDiscountPricing(
    basePrice,
    item.product_type,
    discountPercentage
  )

  let subscriptionPrice = null
  if (['Fragrance', 'Car Fragrance'].includes(item.product_type)) {
    subscriptionPrice = Math.round(discountedPrice * 0.8 * 100) / 100
  }

  return {
    subscriptionPrice,
    oneTimePurchasePrice: parseFloat(discountedPrice),
    comparePrice,
  }
}

export { parsePrice, productPricing, getDiscountPercentage }
